// npm
import React from 'react'
import { graphql, PageProps } from 'gatsby'
import { pageContextProps } from '../../gatsby-node'

// components
import Sections from '@molecules/sections/Sections'
import Layout from '@organisms/global/Layout'

interface Props {
  data: Queries.GeneralPageQuery
  pageContext: PageProps & pageContextProps
}

const GeneralPage = ({ data, pageContext }: Props) => {
  const d = data.datoCmsGeneralPage
  return (
    <Layout
      activeNavSlug={pageContext.activeNavSlug}
      metadata={{
        seoTags: d.seoMetaTags,
      }}
    >
      <Sections sections={d.sections} />
    </Layout>
  )
}

export const query = graphql`
  query GeneralPage($originalId: String!) {
    datoCmsGeneralPage(originalId: { eq: $originalId }) {
      sections {
        ... on DatoCmsSectionContentImage {
          ...sectionContentImage
        }
        ... on DatoCmsSectionText {
          ...sectionText
        }
        ... on DatoCmsSectionClientLogoSlider {
          ...sectionClientLogoSlider
        }
        ... on DatoCmsSectionServiceSlider {
          ...sectionServiceSlider
        }
        ... on DatoCmsSectionContentVideo {
          ...sectionContentVideo
        }
        ... on DatoCmsSectionProjectSlider {
          ...sectionProjectSlider
        }
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
`

export default GeneralPage
